<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <br />
    <v-skeleton-loader
      v-if="isloading"
     
      type=" divider, list-item-three-line"
    ></v-skeleton-loader>
    <div v-else>
      <v-card class="card">
        <v-card-title class="heading justify-center">
          <span>Entry Register</span>
        </v-card-title>
        <v-card-text>
          <div class="add-section">
            <div>
              <v-form ref="form">
                <v-col cols="6" sm="12" lg="6" class="text">
                  <v-col class="pb-0" style="text-align: start">
                    <label class="add-text">User Name</label>
                    <v-autocomplete
                      v-model="selecteduser"
                      @change="findUserInfo()"
                      :items="userlist"
                      item-text="displayname"
                      item-value="id"
                      outlined
                      dense
                      clearable
                      required
                    ></v-autocomplete>
                  </v-col>
                </v-col>
              </v-form>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <div v-if="userInfo" class="cus-card">
        <v-row>
          <div style="width: 80%">
            <v-row style="padding: 0; margin: 0">
              <v-col style="padding: 0; margin: 0" lg="2">
                <label class="c-label">Reg/Emp Code</label></v-col
              >
              <v-col style="padding: 0; margin: 0"
                ><span>: {{ empmemberinfo.uid_no }}</span></v-col
              >
              <v-col style="padding: 0; margin: 0" lg="2">
                <label class="c-label">Is Active </label></v-col
              >
              <v-col style="padding: 0; margin: 0"
                ><span v-if="empmemberinfo.isactive == true">: YES</span>
                <span v-else>: NO</span>
              </v-col>
            </v-row>
            <v-row style="padding: 0; margin: 0">
              <v-col style="padding: 0; margin: 0" lg="2">
                <label class="c-label">Name</label></v-col
              >
              <v-col style="padding: 0; margin: 0"
                ><span>: {{ empmemberinfo.name }}</span>
              </v-col>

              
            </v-row>
            <v-row style="padding: 0; margin: 0">
              <v-col style="padding: 0; margin: 0" lg="2">
                <label style="padding: 0; margin: 0" class="c-label"
                  >Contact</label
                ></v-col
              >
              <v-col style="padding: 0; margin: 0"
                ><span style="padding: 0; margin: 0"
                  >: {{ empmemberinfo.mobile_number }}</span
                >
              </v-col>
              <v-col style="padding: 0; margin: 0" lg="2">
                <label class="c-label">Email</label></v-col
              >
              <v-col style="padding: 0; margin: 0"
                ><span style="padding: 0; margin: 0"
                  >: {{ empmemberinfo.email }}</span
                >
              </v-col>
            </v-row>
            <v-row style="padding: 0; margin: 0">
              <v-col style="padding: 0; margin: 0" lg="2">
                <label class="c-label">User Type</label></v-col
              >
              <v-col style="padding: 0; margin: 0"
                ><span>: {{ empmemberinfo.usertype }}</span></v-col
              >

              <v-col
                v-if="empmemberinfo.program != ''"
                style="padding: 0; margin: 0"
                lg="2"
              >
                <label class="c-label">Program</label></v-col
              >
              <v-col
                v-if="empmemberinfo.program != ''"
                style="padding: 0; margin: 0"
                ><span style="padding: 0; margin: 0"
                  >: {{ empmemberinfo.program }}</span
                >
              </v-col>
            </v-row>
            <v-row style="padding: 0; margin: 0">
              <v-col
                v-if="empmemberinfo.rollno != ''"
                style="padding: 0; margin: 0"
                lg="2"
              >
                <label class="c-label">Roll No</label></v-col
              >
              <v-col
                v-if="empmemberinfo.rollno != ''"
                style="padding: 0; margin: 0"
                ><span style="padding: 0; margin: 0"
                  >: {{ empmemberinfo.rollno }}</span
                >
              </v-col>
              <v-col
                v-if="empmemberinfo.division != ''"
                style="padding: 0; margin: 0"
                lg="2"
              >
                <label class="c-label">Division</label></v-col
              >
              <v-col
                v-if="empmemberinfo.division != ''"
                style="padding: 0; margin: 0"
                ><span style="padding: 0; margin: 0"
                  >: {{ empmemberinfo.division }}</span
                >
              </v-col>
            </v-row>
            <v-row style="padding: 0; margin: 0">
              <v-col
                v-if="empmemberinfo.year"
                style="padding: 0; margin: 0"
                lg="2"
              >
                <label class="c-label">Year</label></v-col
              >
              <v-col v-if="empmemberinfo.year" style="padding: 0; margin: 0"
                ><span style="padding: 0; margin: 0"
                  >: {{ empmemberinfo.year }}</span
                >
              </v-col>
              <v-col
                v-if="empmemberinfo.usertype != 'Employee'"
                style="padding: 0; margin: 0"
                lg="2"
              >
                <label class="c-label">Date Membership</label></v-col
              >
              <v-col
                v-if="empmemberinfo.usertype != 'Employee'"
                style="padding: 0; margin: 0"
                ><span
                  >:
                  <span v-if="empmemberinfo.date_of_membership!=null || empmemberinfo.date_of_membership!=''">
                  {{
                    empmemberinfo.date_of_membership
                      .split("T")[0]
                      .split("-")[2] +
                    "-" +
                    empmemberinfo.date_of_membership
                      .split("T")[0]
                      .split("-")[1] +
                    "-" +
                    empmemberinfo.date_of_membership.split("T")[0].split("-")[0]
                  }} 
                </span>
                  </span
                >
              </v-col>
            </v-row>
          </div>
          <div>
            <v-img height="150" width="150" :src="url">
              <template v-slot:placeholder>
                <v-icon size="150">mdi mdi-account</v-icon>
              </template>
            </v-img>
          </div>
        </v-row>
       
    
      </div>
      <div v-if="userInfo" class="cus-card">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="3" md="3">
          <center><label>In Time</label></center>
    
          <center>
              <input style=" background-color: #FFFFFF; height:50px;    border-radius: 5px;" type="datetime-local"
            v-model="startDate">
          </center>
      </v-col>
        <!-- <v-col cols="12" sm="3" md="3">
          <label>Out Time</label><br/>
    
         
            <input type="datetime-local"
            style=" background-color: #FFFFFF; height:50px;    border-radius: 5px;"
            v-model="endDate">
      </v-col> -->
      </v-row>
      <v-row justify="center" align="center">
        <v-btn class="primary" @click="mark_in_out">mark</v-btn>
        </v-row>
    </div>
    </div>

    <v-data-table  v-if="userInfo"
    :headers="headers"
    :items="table_data"
    :items-per-page="10"
    class="elevation-1"
>
 <template v-slot:top>
  <v-toolbar
    flat
  >
   
 <v-dialog
      v-model="dialog"
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline"> Edit Entry </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
             <label>In Time</label>
              <input style=" background-color: #FFFFFF; height:50px;    border-radius: 5px;" type="datetime-local"
              v-model="editstartDate">
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
              <label>Out Time</label>
              <input style=" background-color: #FFFFFF; height:50px;    border-radius: 5px;" type="datetime-local"
              v-model="editenddate">
            
              </v-col>
           
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="edit_mov_reg()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
     </v-toolbar>
</template>
<template v-slot:item.actions="{ item }">
    <v-icon
      small
      class="mr-2"
      @click="editItem(item)"
    >
     mdi mdi-pencil-box
    </v-icon>
</template>
<template v-slot:item.out_time_display="{ item }">
 <div v-if="item.out_time_display=='' || item.out_time_display==null || item.out_time_display=='null'"> 

<input style=" background-color: #FFFFFF; height:50px;    border-radius: 5px;" type="datetime-local"
@change="markout_time(item)" v-model="item.out_time_display">
</div>
  <div v-else>
    {{item.out_time_display}}  
</div>
</template>
</v-data-table>
  
    
  </div>
  
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard

export default {
  data: () => ({
    headers: [
          { text: 'Srno', value: 'srno' },
          { text: 'InTime', value: 'in_time_display' },
          { text: 'OutTime', value: 'out_time_display' },
          { text: 'Action', value: 'actions' },
        ],
    startDate: "",
            endDate: "",
    fromDateMenu1 : false,
    fromDateMenu: false,
    snackbar_msg: "",
    snackbar: false,
    color: "",
    userInfo: false,
    isloading: false,
    userlist: [],
    selecteduser: "",
    isphoto: false,
    image_url: "",
    url: "",
    btnname:"",
    empmemberinfo:"",
    button_disable:true,
    table_data:[],
    dialog:false,
    editenddate:"",
    editstartDate:"",
    edit_item_id:"",
  }),
  mounted() {
    this.onLoad();

  },
  methods: {
    edit_mov_reg()
    {

      if(this.edit_item_id!='')
      {
        if(this.editstartDate==""||this.editstartDate==null||this.editstartDate=='null'||this.editstartDate=='')
       {
        this.showSnackbar("red", "Please Select In Time Time.");
        return
       }
    
       var params = { mov_reg_id: this.edit_item_id,out_time:this.editenddate,in_time:this.editstartDate };
     
      axios
        .post("/MovementRegister/edit_mov_reg", params)
        .then((res) => {
          console.log(res.data);
          if (res.data.msg == "200") {
            this.showSnackbar("green", "Edit Successfully");
            this.get_entry_info(this.selecteduser);
            this.close();
          } else {
            this.showSnackbar("red", res.data.msg);
            
          }
        });
      }
       
    

    },
    close()
    {
      this.editenddate="";
      this.editstartDate="";
      this.dialog=false;
      this.edit_item_id="";
    },
    editItem(item)
    {
      console.log(item);
      this.editstartDate=item.in_time_edit;
      // this.editenddate='1997-03-04T22:32'
      this.editenddate=item.out_time_edit;
      this.dialog=true;
      this.edit_item_id=item.id;
    },
    mark_in_out() {
       if(this.startDate==""||this.startDate==null||this.startDate=='null'||this.startDate=='')
       {
        this.showSnackbar("red", "Please Select In Time.");
        return
       }
      var params = { userid: this.selecteduser, in:this.startDate,out:"" };
     
      axios
        .post("/MovementRegister/mark_in_out", params)
        .then((res) => {
          console.log(res.data);
          if (res.data.msg == "200") {
            this.showSnackbar("green", "Mark Successfully");
            this.get_entry_info(this.selecteduser)
          } else {
            this.showSnackbar("red", res.data.msg);
            
          }
        });
    },
 
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    onLoad() {
      this.isloading = true;
      axios.get("admin/fetchAllUserwithname").then((res) => {
        //console.log("res------->"+res.data.userType)
        if (res.data.msg == "200") {
          this.userlist = res.data.userlist;
          this.isloading = false;
        } else {
          this.isloading = false;
        }
      });
    },

    findUserInfo() {
      this.isphoto=false;
      this.url="";
      var params = { userid: this.selecteduser };
      this.userInfo = false;
      axios
        .post("/IssueBook/getEmpMemInformationbyUser_ID", params)
        .then((res) => {
         
          if (res.data.msg == "200") {
            this.userInfo = true;
            this.empmemberinfo = res.data.memberinfo;
            this.getimage(this.empmemberinfo.uid_no);
          this.get_entry_info(this.selecteduser)
          } else {
            this.showSnackbar("red", res.data.msg);
            this.userInfo = false;
          }
        });
    },
    
    getimage(value) {
      var params = { prn: value };
      axios.post("/ImportData/getphotfromerp", params).then((res) => {
        if (res.data.msg == "200") {
          this.url = res.data.url;
          this.isphoto = res.data.isphoto;
        }
      });
    },
    get_entry_info(value) {
      
      var params = { userid: value };
      axios.post("/MovementRegister/get_entry_info", params).then((res) => {
        if (res.data.msg == "200") {
         this.table_data=res.data.table_data
        }
      });
    },
    markout_time(value) {
  

     
       if(value.out_time_display==""||value.out_time_display==null||value.out_time_display=='null'||value.out_time_display=='')
       {
        this.showSnackbar("red", "Please Select Out Time Time.");
        return
       }
       var params = { mov_reg_id: value.id,out_time:value.out_time_display };
     
      axios
        .post("/MovementRegister/markout_time", params)
        .then((res) => {
          console.log(res.data);
          if (res.data.msg == "200") {
            this.showSnackbar("green", "Mark Successfully");
            this.get_entry_info(this.selecteduser);
          } else {
            this.showSnackbar("red", res.data.msg);
            
          }
        });
    
  },
},
};
</script>
<style scoped>
.heading {
  background-color: #3f51b5;
  padding: 0.3rem;
  color: white;
}
.v-icon {
  cursor: pointer;
}
.text >>> .v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}
.check >>> .v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}
.p-btn {
  padding: 0px 12px 12px 12px;
}
.add-section {
  display: block;
  text-align: -webkit-center;
}
.checkbox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.cus-card {
  background: #d3d3d33d;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid gray;
}
input[type=datetime-local]:focus {
  border: 3px solid rgb(12, 144, 238);
}
input[type=datetime-local] {
  outline: none;
}
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  height: 30px;
  width: 30px;
}
</style>
